.coming-soon-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.095), #3533cd);
  color: #FFFFFF;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  max-width: 800px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.content-wrapper.loaded {
  opacity: 1;
  transform: translateY(0);
}

.logo-placeholder {
  margin-bottom: 2rem;
}

.logo-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #22215C;
  border: 4px solid #F7C01D;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px rgba(247, 192, 29, 0.5);
  animation: pulse 3s infinite;
}

.text-content {
  text-align: center;
  margin-bottom: 3rem;
}

.main-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.main-title {
  font-size: 4rem;
  font-weight: 800;
  color: #FFFFFF;
  letter-spacing: 4px;
  display: inline-block;
}

.title-spacer {
  display: inline-block;
  width: 30px;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  color: #F7C01D;
  margin-top: 0;
  opacity: 0;
  animation: fadeIn 1s ease 1.5s forwards;
}

.shapes-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.shape {
  position: absolute;
  opacity: 0.6;
  animation: float 10s infinite ease-in-out;
}

.shape-1 {
  top: 10%;
  left: 10%;
  width: 60px;
  height: 60px;
  background-color: #413FAA;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation-delay: 0s;
}

.shape-2 {
  top: 20%;
  right: 15%;
  width: 80px;
  height: 80px;
  background-color: #3A358C;
  border-radius: 50%;
  animation-delay: 1s;
}

.shape-3 {
  bottom: 15%;
  left: 15%;
  width: 70px;
  height: 70px;
  background-color: #22215C;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  animation-delay: 2s;
}

.shape-4 {
  bottom: 25%;
  right: 10%;
  width: 50px;
  height: 50px;
  background-color: #F7C01D;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  animation-delay: 3s;
}

.shape-5 {
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background-color: #413FAA;
  transform: rotate(45deg);
  animation-delay: 4s;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background-color: #22215C;
  text-align: center;
  font-size: 0.9rem;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(247, 192, 29, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(247, 192, 29, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(247, 192, 29, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-title {
    font-size: 3rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
  }
  
  .logo-circle {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 480px) {
  .main-title {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .logo-circle {
    width: 80px;
    height: 80px;
  }
}
